import nope from "nope-validator";
import { StringValidator } from "./types";
import { REGEX } from "../consts";
function checkName(value: string) {
  if (!value || value === "") {
    return "Is required";
  }
  const char_limit = 3;
  if (value.length < char_limit) {
    return "Must be at least 3 characters";
  }
  const special_chars = value.match(/[!@#$%^&*\(\)\.\,-<>?"'~`+]/g);
  if (special_chars) {
    for (const c of special_chars) {
      if (c !== " ") {
        return "Must not have special characters";
      }
    }
  }
  // check if name is all numbers
  // const is_all_numbers = Number.parseFloat(value);
  // if (!Number.isNaN(is_all_numbers)) {
  //   return "Must not be all numbers";
  // }
  const starts_with_number = value.search(/[0-9]/);
  if (starts_with_number >= 0 && starts_with_number < char_limit) {
    return "First 3 characters must not be numbers";
  }
  return undefined;
}
export const full_name: StringValidator = nope.string().test((value) => checkName(value));
export const person_name: StringValidator = nope.string().test((value) => checkName(value));
export const phone_number: StringValidator = nope
  .string()
  .regex(REGEX.PHONE_NUMBER, "Please enter a valid phone number");
export const removeAllCharsFromNumber = (str: string) => {
  return str.replace(/[^0-9]/g, "");
};
